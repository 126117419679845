/* eslint-disable jsdoc/require-jsdoc */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'apps/back-office/src/environments/environment';
import { errorHandler } from '@wp-back-office/app/global-information';
import { MainMenu } from '../models/main.models';
import { ThemeRoot } from '../models/theme.models';
import { Queues } from '../models/queues.model';
import { arrayToDictionary } from '@wp-back-office/core/workflow-sdk';

/**
 * Servicio de dashboard.
 */
@Injectable()
export class DashboardService {
  /**
   * Constante url.
   */
  private URL = environment.endpoints.url;
  /**
   * Constate tenant.
   */
  private TENANT_ID = environment.common.tenantId;
  /**
   * Auditasr proceso.
   */
  private auditProcesses = new BehaviorSubject<any>('');
  /**
   * Auditar detalles.
   */
  public auditDetails = this.auditProcesses.asObservable();

  /**
   * Pool de permisos de la aplicacion.
   */
  public aclApp: any = environment.aclPermissions;

  /**
   * Crea una intancia de DashboardService.
   * @param http - Servicio cliente.
   * @param router - Rutas.
   */
  public constructor(private http: HttpClient, public router: Router) {}

  /**
   * Actualizar detalles auditoria.
   * @param auditDetails - Detalle de auditoria.
   */
  public updateAuditDetail(auditDetails: any) {
    this.auditProcesses.next(auditDetails);
  }

  /**
   * Obetener los procesos para el dashboard.
   * @returns Observable de los procesos.
   */
  public getProcessGroup(): Observable<Queues> {
    return this.http
      .get<Queues>(`${this.URL}/${this.TENANT_ID}/rba/getWorkQueue`)
      .pipe(catchError(errorHandler));
  }

  /**
   * Obtener Tema.
   * @returns Observable.
   */
  public getTheme(): Observable<Record<string, ThemeRoot>> {
    return this.http
      .get<ThemeRoot[]>(
        `${this.URL}/${this.TENANT_ID}/multi-entity-application/theme`
      )
      .pipe(
        catchError(errorHandler),
        map(themes => arrayToDictionary<ThemeRoot>(themes, 'idMarket'))
      );
  }

  /**
   * Obtener Tema.
   * @param themeName - Tema.
   * @returns Observable.
   */
  public getThemeLogo(themeName: string): string {
    return `https://baas-customer-backoffice.s3.amazonaws.com/${
      this.TENANT_ID
    }/themes/${themeName.toLowerCase()}/logo/logo-app`;
  }

  /**
   * Devuelve la configuracion del menu dashboard.
   * @returns MainMenu configuracion del menu dashboard.
   */
  public getConfigMenu(): MainMenu[] {
    return [
      {
        title: 'Inicio',
        icon: 'home',
        routerLink: ['/dashboard', 'home'],
        disableControlPerms: true,
      },
      {
        title: 'Auditar Procesos',
        icon: 'account_tree',
        routerLink: ['/dashboard', 'audit', 'process'],
        permission: this.aclApp.originacion.auditoriaProcesos,
      },
      {
        title: 'Colas de trabajo',
        icon: 'assignment',
        disableControlPerms: true,
      },
      {
        title: 'Tareas asignadas',
        icon: 'assignment_ind',
        disableControlPerms: true,
      },
      {
        title: 'Tareas completadas',
        routerLink: ['/dashboard', 'origination', 'completed-tasks'],
        icon: 'assignment_turned_in',
        permission: this.aclApp.originacion.tareasCompletadas,
      },
      {
        title: 'Iniciar proceso',
        icon: 'forward',
        accordion: [
          {
            title: 'Persona natural',
            icon: 'person',
            permission: this.aclApp.originacion.personaNatural,
            routerLink: [
              '/dashboard',
              'origination',
              'process',
              'm--individual-credit-origination',
              'start',
            ],
          },
          {
            title: 'Persona jurídica',
            icon: 'domain',
            permission: this.aclApp.originacion.personaJuridica,
            routerLink: [
              '/dashboard',
              'origination',
              'process',
              'm--origination-pj',
              'start',
            ],
          },
        ],
      },
      {
        title: 'Configuración',
        icon: 'settings',
        disableControlPerms: true,
        accordion: [
          {
            title: 'Accesos',
            icon: 'settings_account_box',
            accordion: [
              {
                title: 'Usuarios',
                icon: 'manage_accounts',
                permission: this.aclApp.moduloAdministrativo.usuarios,
                routerLink: ['/dashboard', 'access-manager', 'users'],
              },
              {
                title: 'Roles',
                icon: 'group',
                permission: this.aclApp.moduloAdministrativo.roles,
                routerLink: ['/dashboard', 'access-manager', 'roles'],
              },
              {
                title: 'Concesionarios',
                icon: 'location_on',
                permission:
                  this.aclApp.moduloAdministrativo.consecionariosSalas,
                routerLink: ['/dashboard', 'access-manager', 'branch-offices'],
              },
            ],
          },
          {
            title: 'Documentos',
            icon: 'folder',
            accordion: [
              {
                title: 'Plantillas',
                icon: 'description',
                permission: this.aclApp.moduloAdministrativo.plantillas,
                routerLink: [
                  '/dashboard',
                  'template-management',
                  'document-templates',
                ],
              },
              {
                title: 'Cláusulas legales',
                icon: 'receipt_long',
                permission: this.aclApp.moduloAdministrativo.plantillas,
                routerLink: [
                  '/dashboard',
                  'template-management',
                  'legal-document',
                ],
              },
            ],
          },
          {
            title: 'Solicitudes',
            icon: 'request_page',
            accordion: [
              {
                title: 'Reasignar',
                icon: 'move_down',
                permission: this.aclApp.moduloAdministrativo.reasignacion,
                routerLink: ['/dashboard', 'access-manager', 'reassign-tasks'],
              },
              {
                title: 'Ver ATDP',
                icon: 'move_down',
                permission: this.aclApp.moduloAdministrativo.atdp,
                routerLink: ['/dashboard', 'admin-task', 'atdp'],
              },
            ],
          },
          {
            title: 'Listas internas',
            icon: 'toc',
            accordion: [
              {
                title: 'Restrictiva',
                icon: 'filter_list',
                permission: this.aclApp.moduloAdministrativo.listaRestrictiva,
                routerLink: [
                  '/dashboard',
                  'internal-lists',
                  'list-restrictive',
                ],
              },
              {
                title: 'Preaprobados',
                icon: 'sort',
                permission: this.aclApp.moduloAdministrativo.listaPreaprobados,
                routerLink: [
                  '/dashboard',
                  'internal-lists',
                  'list-pre-approved',
                ],
              },
            ],
          },
          {
            title: 'Productos',
            icon: 'inventory_2',
            accordion: [
              {
                title: 'Vehículos',
                icon: 'directions_car',
                permission: this.aclApp.productosServicios.vehiculos,
                routerLink: ['/dashboard', 'admin-products', 'vehicles'],
              },
              {
                title: 'Precios',
                icon: 'border_color',
                permission: this.aclApp.productosServicios.prices,
                routerLink: ['/dashboard', 'admin-products', 'prices'],
              },
              {
                title: 'Servicios',
                icon: 'lan',
                permission: this.aclApp.productosServicios.servicios,
                routerLink: ['/dashboard', 'admin-products', 'services'],
              },
              {
                title: 'Planes Financieros',
                icon: 'inventory',
                permission: this.aclApp.productosServicios.planesFinancieros,
                routerLink: [
                  '/dashboard',
                  'settings',
                  'product-settings',
                  'financial-plans',
                ],
              },
            ],
          },
          {
            title: 'Árboles de decisión',
            icon: 'account_tree',
            accordion: [
              {
                title: 'Tarificación',
                permission: this.aclApp.productosServicios.tarificacion,
                icon: 'design_services',
                routerLink: [
                  '/dashboard',
                  'admin-products',
                  'decision-tree',
                  'TARIFICATION',
                ],
              },
              {
                title: 'Seguros',
                icon: 'design_services',
                permission: this.aclApp.reglasDecision.segurosExtraprima,
                routerLink: [
                  '/dashboard',
                  'admin-products',
                  'decision-tree',
                  'INSURANCE',
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'Salir',
        icon: 'logout',
        action: 'closeSession',
        disableControlPerms: true,
      },
      // {
      //   title: 'Probar formulario JSON',
      //   icon: 'science',
      //   routerLink: ['/dashboard', 'form-test'],
      // },
    ];
  }
}
