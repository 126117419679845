import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SignInService } from '@wp-back-office/app/user-authentication';
import { environment } from '../../environments/environment';

/**
 * Servicio que intercepta las peticiones HTTP.
 */
@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  /**
   * Instancia del servicio de Interseptor.
   *
   * @param serviceSignIn - Servicio de autenticacion.
   */
  constructor(public serviceSignIn: SignInService) {}

  /**
   *Identifies and handles a given HTTP request.
   *
   * @param req - The outgoing request object to handle.
   * @param next - The next interceptor in the chain.
   * @returns - The observable of the request.
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    const { idToken } = this.serviceSignIn.loadSession();
    if (!idToken) return next.handle(req);

    const authReq = this.valideTokenRequest(req, idToken);
    return next.handle(authReq);
  }

  /**
   * Valida que el endpoint sea el correcto para enviar el token.
   * @param req - Request.
   * @param token - Token.
   * @returns HttpRequest<any>.
   */
  public valideTokenRequest(
    req: HttpRequest<any>,
    token: any
  ): HttpRequest<any> {
    const workFlow = environment.endpoints.workFlow;
    const urlPAS = environment.endpoints.urlPAS;
    const url = environment.endpoints.url;
    const urlIntegrations = environment.endpoints.urlIntegrations;
    const urlBackofficeRules = environment.endpoints.backofficeRules;
    const urlDocuments = environment.endpoints.urlDocuments;
    if (
      req.url.includes(urlIntegrations) ||
      req.url.includes(url) ||
      req.url.includes(workFlow) ||
      req.url.includes(urlPAS) ||
      req.url.includes(urlBackofficeRules)||
      req.url.includes(urlDocuments)
    ) {
      return token
        ? req.clone({ setHeaders: { Authorization: 'Bearer ' + token } })
        : req;
    } else {
      return req;
    }
  }
}
