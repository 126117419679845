import { Enviroment } from '@wp-back-office/core/commons-backoffice';
import { aclPermissions } from './permissions';

export const environment: Enviroment = {
  production: false,
  valueRecaptcha: '6Lf8KLUZAAAAAPZm2Q3Ow1G7Ix3W_cX4DStxXLJG',
  endpoints: {
    url: 'https://tmte3pr9t6.execute-api.us-east-1.amazonaws.com/prod/external',
    urlPAS:
      'https://tifzazcvl4.execute-api.us-east-1.amazonaws.com/prod/external',
    workFlow:
      'https://b1quasrjaf.execute-api.us-east-1.amazonaws.com/prod/external',
    urlBuckets: 'https://bank4us-prod-cus-documents.s3.amazonaws.com',
    urlIntegrations:
      'https://t3a9z73ceg.execute-api.us-east-1.amazonaws.com/prod/external',
    urlApl: 'https://z7r9hjev1k.execute-api.us-east-1.amazonaws.com/prod',
    urlArus: 'https://moxrfdvxyd.execute-api.us-east-1.amazonaws.com/prod',
    urlCerticamara: 'https://alb.rci.bank4us.co',
    urlRuleValidationPj:
      'https://28yokxxczb.execute-api.us-east-1.amazonaws.com/prod',
    urlAuth: 'https://tdjdfcbn9a.execute-api.us-east-1.amazonaws.com/prod',
    backofficeRules:
      'https://0uufvmo5mc.execute-api.us-east-1.amazonaws.com/prod/external',
    urlDocuments: 'https://prod.documents-manager.alb.rci.bank4us.co',
  },
  common: {
    tenantId: '1111111',
  },
  bodyCerticamara: {
    ProcessTypeEnum: 'Produccion_1',
  },
  aws: {
    awsAccount: '872865219972',
    region: 'us-east-1',
  },
  aclPermissions: Object.freeze(aclPermissions),
  publicKey: `-----BEGIN PUBLIC KEY-----
  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA4Um0qQWfCWPwA+hLuhLR
  tAuKJND6SDkY9dhQlWI521+hqmMZnfkyhPIl39GyxQohxCpyJ6lQgLkfFtIejbZt
  /XyGjTUBpaaU/VI2ujjtGapJQOH77HcxL+VbE+uaX8Q0hJA24w0td/o46y8A4Ryl
  V9R86kQON+Gag6ALzdRpcmgRdYoEsPN1i1FVzdi+uSkVcH/wXmjEpOvEnF7H41fW
  /DHL1jZYjPr5yahavgM/p1iS2O3689FkBCD55G2M0Q9nDG87/SWJ8D8i7xFxe0XJ
  gpmcmL3Bcz/TZx3HN/YYLtUgHVFvFem7MogZdyFspq9EqjFsg1VEVrt4I5mo7VJj
  1QIDAQAB
  -----END PUBLIC KEY-----`,
  SESSION_KEY: {
    idToken: String(
      Symbol('yin6$vVfc7bZ7VGI$9Ld7@%!zx!4Clxt3YY^mQD$UmCUboW%11')
    ),
    accessToken: String(
      Symbol('ClDI4pdDAeVy@hc4sgcT!afoINj3al9$B!CA*8z2tq9@96qnsk')
    ),
    refreshToken: String(
      Symbol('tgkEjfSgnEmVJSi*1*rIjncqSty4*g%3aR4V7zC$X*d1I87JVv')
    ),
  },
};
